import React from 'react';
import CircleProgressBar from '../components/CircleProgressBar';
import ClientSection from '../components/ClientSection';
import {ContactSection} from '../components/ContactSection';
import { Link as ScrollLink } from 'react-scroll';

function Banner() {
  return (
    <>
    <section className="home-banner" style={{ backgroundImage: `url(${require("../assets/images/agency-img01.jpg")})` }}>
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <div className="banner-content text-center">
                        <div className="section-head">
                            <div className="divider-wrapper">
                                <span className="title-divider">
                                    <span className="top-title">KlickPe</span>
                                </span>
                            </div>
                            <h2 className="section-title banner-title">
                                <span className="title-highlight">Finance</span>
                                Agency You Can Rely Upon !
                            </h2>
                        </div>
                        <div className="banner-text">
                            <p>Hymenaeos! Omnis arcu vitae? Phasellus irure cupidatat incidunt, semper metus, nibh adipisci assumenda odio, quos, senectus vehicula occaecat, officiis malesuada cras.</p>
                        </div>
                        <div className="banner-button">
                        <ScrollLink
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70} // Adjust this value to fit your layout
                      duration={500}
                      className="button-round-primary"
                    >
                      DISCOVER MORE
                    </ScrollLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="overlay"></div>
    </section>
    </>
  );
}
function HomeService() {
  return (
    <>
      <section className="service-section" id="service">
        <div className="service-inner-wrap">
            <div className="container">
                <div className="overlay"></div>
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="section-head white-section-head">
                            <div className="divider-wrapper">
                                <span className="title-divider">
                                    <span className="top-title">SERVICE LIST</span>
                                </span>
                            </div>
                            <h2 className="section-title">
                                <span className="title-highlight">Services</span>
                                We Can Help You With !
                            </h2>
                            <div className="section-disc">
                                <p>
                                    Vero id posuere tempus aspernatur quaerat mollis voluptatum eveniet porro viverra libero habitasse porro fames quos pharetra diam cupidatat ligula! Netus! Quos dignissi.
                                </p>
                                <div className="service-list">
                                    <ul>
                                        <li>
                                            <i className="far fa-dot-circle"></i>
                                            <span className="dot-list-text">Nobis ridiculus cupiditate egestas commodi.</span>
                                        </li>
                                        <li>
                                            <i className="far fa-dot-circle"></i>
                                            <span className="dot-list-text">Ipsum dui curabitur dapibuish mollitia.</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="types-of-duty">
                            <div className="service-type">
                                <div className="overlay75"></div>
                                <div className="service-icon">
                                    <i aria-hidden="true" className="icon icon-chart2"></i>
                                </div>
                                <h5 className="service-title">
                                    Money Transfer
                                </h5>
                            </div>
                            <div className="service-type">
                                <div className="overlay75"></div>
                                <div className="service-icon">
                                    <i aria-hidden="true" className="icon icon-design"></i>
                                </div>
                                <h5 className="service-title">
                                    AePS
                                </h5>
                            </div>
                            <div className="service-type">
                                <div className="overlay75"></div>
                                <div className="service-icon">
                                    <i aria-hidden="true" className="icon icon-Document-Search"></i>
                                </div>
                                <h5 className="service-title">
                                    Bill Payment
                                </h5>
                            </div>
                            <div className="service-type">
                                <div className="overlay75"></div>
                                <div className="service-icon">
                                    <i aria-hidden="true" className="icon icon-api_setup"></i>
                                </div>
                                <h5 className="service-title">
                                    Recharge
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="service-item-inner">
            <div className="container">
                <div className="service-contact-wrapper primary-bg ">
                    <div className="contact-package col-xl-5 col-lg-5 col-md-12">
                        <div className="contact-number-tag d-flex align-items-center">
                            <figure className="contact-icon">
                                <span className="icon-outer-circle">
                                    <i aria-hidden="true" className="icon icon-phone-call2"></i>
                                </span>
                            </figure>
                            <div className="contact-detail-tag">
                                <h6>GET A FREE CONSULTATION</h6>
                                <span>987 233 0000</span>
                            </div>
                        </div>
                    </div>
                    <div className="contct-info col-xl-4 col-lg-4 col-md-12">
                        <p>
                            Vero id posuere tempus aspernatur quaerat mollis voluptatum eveniet porro viverra libero habitasse Aut ab nibh aliqua optio veniet porro.
                        </p>
                    </div>
                    <div className="contactbtn col-xl-3 col-lg-3 col-md-12">
                        <ScrollLink
                          to="contact-section"
                          spy={true}
                          smooth={true}
                          offset={-70} // Adjust this value to fit your layout
                          duration={500}
                          className="button-round-white"
                          style={{ cursor: 'pointer' }}
                        >
                          MAKE APPOINTMENT
                        </ScrollLink>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
}
function AboutSection() {
  return (
    <>
    <section className="about-section" id="about">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="section-about-left about-image-wrap d-flex flex-wrap">
                        <div className="home-about-image left-image">
                            <img src={require("../assets/images/agency-img16-500.jpg")} alt="" />
                            <div className="video-button">
                                <a id="video-container-two" data-video-id="IUN664s7N-c">
                                    <i className="fas fa-play"></i>
                                </a>
                            </div>
                        </div>
                        <div className="home-about-image right-image"><img src={require("../assets/images/agency-img2.jpg")} alt="" />
                        </div>
                        <div className="home-about-image bottom-image">
                            <img src={require("../assets/images/agency-img15-800x533.jpg")} alt="" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="section-about-right">
                        <div className="section-about-content">
                            <div className="section-head">
                                <div className="divider-wrapper">
                                    <span className="title-divider">
                                        <span className="top-title">ABOUT US</span>
                                    </span>
                                </div>
                                <h2 className="section-title">
                                    <span className="title-highlight">Introduction </span>
                                    To Best Digital Agency!
                                </h2>
                                <div className="section-disc">
                                    <p>
                                        Sint nascetur facere, delectus conubia consequuntur, nonummy distinctio! Non officiis, id natus non nisl provident justo.
                                    </p>
                                </div>
                            </div>
                            <div className="about-list">
                                <ul>
                                    <li>Praesentium voluptatum dolores</li>
                                    <li>Cillum nullam rem volutpat earum.</li>
                                    <li>Odio doloribus lacus quaerat assum.</li>
                                    <li>Natoque, cubilia eos ipsa, vehicula.</li>
                                    <li>Cillum nullam rem volutpat earum.</li>
                                    <li>Odio doloribus lacus quaerat assum.</li>
                                </ul>
                                <div className="exp-date">
                                    <h2>24</h2>
                                    <h4>YEARS</h4>
                                    <span className="date-decs">OF EXPERIENCE</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
}
function FactSection() {
  return (
    <>
    <section className="fact-section">
        <div className="fact-overlay"></div>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="fact-item-wrap d-flex flex-wrap justify-content-center">
                        <div className="circle-overlay"></div>
                        <div className="fact-section-head">
                            <div className="divider-wrapper">
                                <span className="title-divider">
                                    <span className="top-title">OUR GOAL</span>
                                </span>
                            </div>
                            <h2 className="section-title">
                                <span className="title-highlight"> Mission </span>
                                We Aim For You!
                            </h2>
                            <div className="section-disc">
                                <p>
                                    Sint nascetur facere, delectus conubia consequuntur, nonummy distinctio justo.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <CircleProgressBar/>
                </div>
            </div>
        </div>
        <div className="overlay"></div>
    </section>
    </>
  );
}
function GallerySection(){
    return (
    <>
    <section className="gallery-section" id="portfolio">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2 col-md-12">
                    <div className="section-head text-center">
                        <div className="divider-wrapper">
                            <span className="title-divider">
                                <span className="top-title">OUR PORTFOLIO</span>
                            </span>
                        </div>
                        <h2 className="section-title">
                            <span className="title-highlight">Activities </span>
                            We Completed
                        </h2>
                        <div className="section-disc">
                            <p>
                                Sint nascetur facere, delectus conubia consequuntur, nonummy distinctio! Non officiis, id natus non nisl provident justo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gallery-inner">
                <div className="gallery-container">
                    <div className="single-gallery">
                        <figure className="gallery-img">
                            <a href={require("../assets/images/agency-img27.jpg")} data-fancybox="gallery">
                                <img src={require("../assets/images/agency-img27.jpg")} alt="" /> 
                            </a>
                            <div className="facility-wrapper">
                                <div className="facility-content">
                                    <h4 className="facility-title">
                                        <a href="/">Money Transfer</a>
                                    </h4>
                                    <p className="facility-info">
                                        Ullam aliquet mattis dignissim scelerisque anim exercitation officia, accusamus potenti ipsum, similique.
                                    </p>
                                </div>
                            </div>
                        </figure>
                    </div>
                    <div className="single-gallery">
                        <figure className="gallery-img">
                            <a href={require("../assets/images/agency-img26.jpg")} data-fancybox="gallery">
                                <img src={require("../assets/images/agency-img26.jpg")} alt="" /> 
                            </a>
                            <div className="facility-wrapper">
                                <div className="facility-content">
                                    <h4 className="facility-title">
                                        <a href="/">AePS</a>
                                    </h4>
                                    <p className="facility-info">
                                        Ullam aliquet mattis dignissim scelerisque anim exercitation officia, accusamus potenti ipsum, similique.
                                    </p>
                                </div>
                            </div>
                        </figure>
                    </div>
                    <div className="single-gallery">
                        <figure className="gallery-img">
                            <a href={require("../assets/images/agency-img25.jpg")} data-fancybox="gallery">
                                <img src={require("../assets/images/agency-img25.jpg")} alt="" /> 
                            </a>
                            <div className="facility-wrapper">
                                <div className="facility-content">
                                    <h4 className="facility-title">
                                        <a href="/">Bill Payment</a>
                                    </h4>
                                    <p className="facility-info">
                                        Ullam aliquet mattis dignissim scelerisque anim exercitation officia, accusamus potenti ipsum, similique.
                                    </p>
                                </div>
                            </div>
                        </figure>
                    </div>
                    <div className="single-gallery">
                        <figure className="gallery-img">
                            <a href={require("../assets/images/agency-img24.jpg")} data-fancybox="gallery">
                                <img src={require("../assets/images/agency-img24.jpg")} alt="" /> 
                            </a>
                            <div className="facility-wrapper">
                                <div className="facility-content">
                                    <h4 className="facility-title">
                                        <a href="/">Recharge</a>
                                    </h4>
                                    <p className="facility-info">
                                        Ullam aliquet mattis dignissim scelerisque anim exercitation officia, accusamus potenti ipsum, similique.
                                    </p>
                                </div>
                            </div>
                        </figure>
                    </div>
                    <div className="single-gallery">
                        <figure className="gallery-img">
                            <a href={require("../assets/images/agency-img23.jpg")} data-fancybox="gallery">
                                <img src={require("../assets/images/agency-img23.jpg")} alt="" /> 
                            </a>
                            <div className="facility-wrapper">
                                <div className="facility-content">
                                    <h4 className="facility-title">
                                        <a href="/">DTH</a>
                                    </h4>
                                    <p className="facility-info">
                                        Ullam aliquet mattis dignissim scelerisque anim exercitation officia, accusamus potenti ipsum, similique.
                                    </p>
                                </div>
                            </div>
                        </figure>
                    </div>
                    <div className="single-gallery">
                        <figure className="gallery-img">
                            <a href={require("../assets/images/agency-img7.jpg")} data-fancybox="gallery">
                                <img src={require("../assets/images/agency-img7.jpg")} alt="" /> 
                            </a>
                            <div className="facility-wrapper">
                                <div className="facility-content">
                                    <h4 className="facility-title">
                                        <a href="/">LIC</a>
                                    </h4>
                                    <p className="facility-info">
                                        Ullam aliquet mattis dignissim scelerisque anim exercitation officia, accusamus potenti ipsum, similique.
                                    </p>
                                </div>
                            </div>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    )
}
function TestimonialSection(){
    return (
    <>
    <section className="testimonial-section ">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-5 col-lg-12">
                    <div className="section-head">
                        <div className="divider-wrapper">
                            <span className="title-divider">
                                <span className="top-title">CLIENT REVIEWS</span>
                            </span>
                        </div>
                        <h2 className="section-title">
                            <span className="title-highlight">Review's </span>
                            Of Clients
                        </h2>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-12">
                    <div className="section-disc">
                        Vero id posuere tempus aspernatur quaerat mollis voluptatum eveniet porro viverra libero habitasse porro fames quos pharetra diam cupidatat ligula reet.
                    </div>
                </div>
            </div>
            <div className="testimonial-inner">
                <div className="row d-flex justify-content-center justify-content-xl-start">
                    <div className="vow-item col-lg-4 col-md-6">
                        <div className="testimonial-item">
                            <figure className="testimonial-img">
                                <img src={require("../assets/images/agency-img17.jpg")} alt="" />
                            </figure>
                            <div className="testimonial-content">
                                <div className="comment-info">
                                    <p>Vero id posuere tempus aspernatur quaerat mollis voluptatum eveniet porro viverra libero habitasse porro.</p>
                                </div>
                                <div className="author-content">
                                    <div className="author-info">
                                        <h5 className="author-name">John Smith</h5>
                                        <span className="author-work">Clients</span>
                                    </div>
                                    <div className="rating-start-wrap">
                                        <div className="rating-start">
                                            <span style={{width: '100%'}}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="vow-item col-lg 4 col-md-6">
                        <div className="testimonial-item">
                            <figure className="testimonial-img">
                                <img src={require("../assets/images/agency-img19.jpg")} alt="" />
                            </figure>
                            <div className="testimonial-content">
                                <div className="comment-info">
                                    <p>Vero id posuere tempus aspernatur quaerat mollis voluptatum eveniet porro viverra libero habitasse porro.</p>
                                </div>
                                <div className="author-content">
                                    <div className="author-info">
                                        <h5 className="author-name">MIley Cyrus</h5>
                                        <span className="author-work">Supervisor</span>
                                    </div>
                                    <div className="rating-start-wrap">
                                        <div className="rating-start">
                                            <span style={{width: '80%'}}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="vow-item col-lg-4 col-md-6">
                        <div className="testimonial-item">
                            <figure className="testimonial-img">
                                <img src={require("../assets/images/agency-img20.jpg")} alt="" />
                            </figure>
                            <div className="testimonial-content">
                                <div className="comment-info">
                                    <p>Vero id posuere tempus aspernatur quaerat mollis voluptatum eveniet porro viverra libero habitasse porro.</p>
                                </div>
                                <div className="author-content">
                                    <div className="author-info">
                                        <h5 className="author-name">Thomas Walter</h5>
                                        <span className="author-work">Customer</span>
                                    </div>
                                    <div className="rating-start-wrap">
                                        <div className="rating-start">
                                            <span style={{width: '90%'}}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="pattern-overlay"></div>
    </section>
    </>
    )
}
function Home(){
    return(
    <>
        <Banner />
        <HomeService />
        <AboutSection />
        <ClientSection />
        <FactSection />
        <GallerySection />
        <TestimonialSection />
        <ContactSection />
    </>
    )
}
export {Home};
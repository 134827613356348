import React from 'react';
import $ from 'jquery';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ClientSection() {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: false,
    slidesToShow: 5,
    autoplay: false,
    draggable: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <>
    <div className="client-section">
      <div className="container text-center">
        <Slider {...sliderSettings}>
          <div className="client-item">
            <figure>
              <img src={require("../assets/images/agency-img4.png")} alt="" />
            </figure>
          </div>
          <div className="client-item">
            <figure>
              <img src={require("../assets/images/agency-img5.png")} alt="" />
            </figure>
          </div>
          <div className="client-item">
            <figure>
              <img src={require("../assets/images/agency-img6.png")} alt="" />
            </figure>
          </div>
          <div className="client-item">
            <figure>
              <img src={require("../assets/images/agency-img7.png")} alt="" />
            </figure>
          </div>
          <div className="client-item">
            <figure>
              <img src={require("../assets/images/agency-img8.png")} alt="" />
            </figure>
          </div>
          <div className="client-item">
            <figure>
              <img src={require("../assets/images/agency-img5.png")} alt="" />
            </figure>
          </div>
        </Slider>
      </div>
    </div>
    </>
  );
}

export default ClientSection;

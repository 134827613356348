import React, { useState, useEffect } from 'react';
import $ from 'jquery';

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <a href="#"
      id="backTotop"
      style={{ display: showButton ? 'block' : 'none' }}
      onClick={scrollToTop}
      className="to-top-icon"
    >
      <i className="fas fa-chevron-up"></i>
    </a>
  );
};

function ScrollListener() {

  useEffect(() => {
    const handleLoad = () => {
      $('#siteLoader').fadeOut(1000);
    };

    // Event listener for window load
    window.addEventListener('load', handleLoad);

    const handleScroll = () => {
      const headerElement = document.querySelector('.site-header');
      const mastheadElement = document.getElementById('masthead');

      if (!headerElement || !mastheadElement) return;

      const headerHeight = headerElement.offsetHeight;
      const bottomheaderHeight = headerElement.offsetHeight;
      const mobileheaderHeight = headerHeight + bottomheaderHeight;

      if (window.innerWidth >= 992) {
        if (window.scrollY > headerHeight) {
          mastheadElement.classList.add('fixed-header');
        } else {
          mastheadElement.classList.remove('fixed-header');
        }
      } else {
        if (window.scrollY > mobileheaderHeight) {
          mastheadElement.classList.add('fixed-header');
        } else {
          mastheadElement.classList.remove('fixed-header');
        }
      }
    };
    document.addEventListener('scroll', handleScroll);
    // Cleanup
    return () => {
      window.removeEventListener('load', handleLoad);
      document.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render

  // return null; // This component doesn't render anything visible
}

export {BackToTopButton,ScrollListener};

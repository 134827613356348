import React,{useState } from 'react';
import Head from './Head';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';


function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Head pageTitle="KlickPe" pageDescription="Description of my page" />
      <header id="masthead" className="site-header">
        <div className="container">
          <div className="hgroup-wrap d-flex justify-content-between align-items-sm-center">
            <div className="site-identity">
              <p className="site-title">
                <Link to="/">
                  <img src={require("../assets/images/logo1.png")} alt="logo"/>
                </Link>
              </p>
            </div>
            <div className="main-navigation">
              <nav id="navigation" className="navigation d-none d-lg-inline-block">
                <ul>
                <li><a href="/">Home</a></li>
                <li><ScrollLink
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70} // Adjust this value to fit your layout
                      duration={500}
                      style={{ cursor: 'pointer' }}
                    >
                      About
                    </ScrollLink></li>
                <li><ScrollLink
                      to="service"
                      spy={true}
                      smooth={true}
                      offset={-70} // Adjust this value to fit your layout
                      duration={500}
                      style={{ cursor: 'pointer' }}
                    >
                      service
                    </ScrollLink></li>
                <li><ScrollLink
                      to="portfolio"
                      spy={true}
                      smooth={true}
                      offset={-70} // Adjust this value to fit your layout
                      duration={500}
                      style={{ cursor: 'pointer' }}
                    >
                      portfolio
                    </ScrollLink></li>
                <li><ScrollLink
                      to="contact-section"
                      spy={true}
                      smooth={true}
                      offset={-70} // Adjust this value to fit your layout
                      duration={500}
                      style={{ cursor: 'pointer' }}
                    >
                      contact
                    </ScrollLink></li></ul>
              </nav>
            </div>
            <div className="side-nav d-flex justify-content-between align-items-center">
              <div className="header-btn d-inline-block">
                <a href="https://www.klickpe.in/ws" className="button-round-primary">Login</a>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-menu-container">
          <div className="slicknav_menu d-lg-none d-block">
            <a href="#" aria-haspopup="true" role="button" tabIndex="0" className={`slicknav_btn ${isOpen ? 'slicknav_open' : 'slicknav_collapsed'}`} onClick={handleToggle} style={{outline: 'none'}}>
              <span className="slicknav_menutxt">Menu</span><span className="slicknav_icon"><span className="slicknav_icon-bar"></span>
              <span className="slicknav_icon-bar"></span><span className="slicknav_icon-bar"></span></span></a>
              <nav className={`slicknav_nav ${isOpen ? 'show' : 'hide'}`} aria-hidden="true" role="menu">
                            <ul>
                                <li><ScrollLink
                                      to="about"
                                      spy={true}
                                      smooth={true}
                                      offset={-70} // Adjust this value to fit your layout
                                      duration={500}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      About
                                    </ScrollLink></li>
                                <li><ScrollLink
                                      to="service"
                                      spy={true}
                                      smooth={true}
                                      offset={-70} // Adjust this value to fit your layout
                                      duration={500}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      service
                                    </ScrollLink></li>
                                <li><ScrollLink
                                      to="portfolio"
                                      spy={true}
                                      smooth={true}
                                      offset={-70} // Adjust this value to fit your layout
                                      duration={500}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      portfolio
                                    </ScrollLink></li>
                                <li><ScrollLink
                                      to="contact-section"
                                      spy={true}
                                      smooth={true}
                                      offset={-70} // Adjust this value to fit your layout
                                      duration={500}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      contact
                                    </ScrollLink></li>
                            </ul>
                        </nav></div>
        </div>
      </header>
    </>
  );
}
export {Header};

import React,{useEffect, useRef} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './assets/vendors/elementskit-icon-pack/assets/css/ekiticons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript
import '@fortawesome/fontawesome-free/css/all.css'; // import Font Awesome CSS
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';

import {ScrollListener,BackToTopButton} from './components/Custom';
import {Header} from './components/Header';
import Footer from './components/Footer';
import {Home} from './pages/Home';
import NotFound from './pages/NotFound';
import loader1 from './assets/images/loader1.gif'; // Import the image
import './assets/css/style.css';

function App() {
  return (
    <Router>
        <ScrollListener/>
        <div id="siteLoader" className="site-loader ">
            <div className="preloader-content">
                <img src={loader1} alt=""/>
            </div>
        </div>
        <div id="page" className="full-page">
          <Header />
          <main id="content" className="site-main">
            <Routes>
              <Route
                  path="/"
                  element={
                    <>
                      <Home />
                    </>
                  }
                />
              <Route
                  path="*"
                  element={
                    <>
                      <NotFound />
                    </>
                  }
                />
            </Routes>
            <BackToTopButton />
          </main>
          <Footer />
        </div>
    </Router>
  );
}

export default App;

import React, { useState } from 'react';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) {
      errors.name = 'Name is required';
    } else if (!/^[a-zA-Z\s]*$/.test(name)) {
      errors.name = 'Name can only contain alphabets and spaces';
    }
    if (!mobile.trim()) {
      errors.mobile = 'Mobile is required';
    } else if (!/^[6-9]\d{9}$/.test(mobile)) {
      errors.mobile = 'Please enter a valid mobile number';
    } else if (!/^\d+$/.test(mobile)) {
      errors.mobile = 'Mobile number can only contain digits';
    }
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!validateEmail(email)) {
      errors.email = 'Please enter a valid email address';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    setErrorMessage('');
    setSuccessMessage('');

    if (!validateForm()) {
      return;
    }
    
          setName('');
          setEmail('');
          setMobile('');
          setMessage('');
    setSuccessMessage('Form submitted successfully');

    // Assuming you have an API endpoint to save the form data
    // try {
    //   const response = await fetch('http://localhost:3000/api/contact', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({ name: name.trim(), email: email.trim(), message: message.trim(), mobile: mobile.trim() }),
    //   });

    //   if (response.ok) {
    //     const data = await response.json();
    //     data.success ? setSuccessMessage(data.message) : setErrorMessage(data.message);
    //     if(data.success){
    //       setName('');
    //       setEmail('');
    //       setMobile('');
    //       setMessage('');
    //       setErrors({});
    //     }
    //     // Reset form fields and errors
        
    //   } else {
    //     const data1 = await response.json();
    //     data1.success ? setSuccessMessage(data1.message) : setErrorMessage(data1.message);
    //   }
    // } catch (error) {
    //   console.error('Error:', error);
    //   setErrorMessage('Failed to submit message. Please try again later.');
    //   setSuccessMessage('');
    // }
  };

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleInputFocus = (field) => {
    setErrors((prevErrors) => {
      return {
        ...prevErrors,
        [field]: undefined,
      };
    });
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    const formattedName = value
      .toLowerCase() // Convert the input to lowercase
      .replace(/[^a-zA-Z\s]/g, '') // Allow only alphabets and spaces
      .split(' ') // Split the name into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back together with spaces
    setName(formattedName);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMobileChange = (event) => {
    const { value } = event.target;
    const newValue = value.replace(/\D/g, ''); // Allow only digits
    if (!newValue || (newValue[0] >= '6' && newValue[0] <= '9')) {
      setMobile(newValue);
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <div className="home-contact-form">
      <h4 className="form-title">Get In Touch !</h4>
      <p className="form-detail">Vero id posuere tempus aspernatur quaerat mollis voluptatum eveniet porro</p>
      <form className="contact-form row" onSubmit={handleSubmit} autoComplete="off">
        <div className="col-12 form-height">
          <input
            type="text"
            className={`form-control ${errors.name && 'is-invalid'}`}
            placeholder="Your Name*"
            value={name}
            onChange={handleNameChange}
            onFocus={() => handleInputFocus('name')}
          />
          {errors.name && <div className="invalid-feedback">{errors.name}</div>}
        </div>
        <div className="col-12 form-height">
          <input
            type="text"
            className={`form-control ${errors.email && 'is-invalid'}`}
            placeholder="Your Email*"
            value={email}
            onChange={handleEmailChange}
            onFocus={() => handleInputFocus('email')}
          />
          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </div>
        <div className="col-12 form-height">
          <input
            type="text"
            className={`form-control ${errors.mobile && 'is-invalid'}`}
            placeholder="Your Mobile*"
            maxLength="10"
            value={mobile}
            onChange={handleMobileChange}
            onFocus={() => handleInputFocus('mobile')}
          />
          {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
        </div>
        <div className="col-sm-12 form-height">
          <textarea
            placeholder="Enter message"
            rows="6"
            className={`form-control ${errors.message && 'is-invalid'}`}
            value={message}
            onChange={handleMessageChange}
            onFocus={() => handleInputFocus('message')}
          ></textarea>
          {errors.message && <div className="invalid-feedback">{errors.message}</div>}
        </div>
        <div className="col-12 text-center text-md-start mt-3">
          <button type="submit" className="button-round-primary">
            submit message
          </button>
          {successMessage && <div className="text-success mt-2">{successMessage}</div>}
          {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
        </div>
      </form>
    </div>
  );
}

export default ContactForm;

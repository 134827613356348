import React from 'react';
import ContactForm from './ContactForm';

function ContactSection() {
  return (
    <>
    <section className="contact-section" id="contact-section" style={{ backgroundImage: `url(${require("../assets/images/agency-img29.jpg")})` }}>
      <div className="container">
          <div className="row align-items-center">
              <div className="col-lg-4">
                  <div className="contact-section-head">
                      <div className="divider-wrapper">
                          <span className="title-divider">
                              <span className="top-title">CONTACT US</span>
                          </span>
                      </div>
                      <h2 className="section-title">
                          <span className="title-highlight">Contact </span>
                          & Join Together
                      </h2>
                      <div className="section-disc">
                          <p>
                              Sint nascetur facere, delectus conubia consequuntur, nonummy distinctio! Non officiis, id natus non nisl provident justo.
                          </p>
                      </div>
                      <div className="contact-detail-wrapper">
                          <div className="contact-detail-container">
                              <div className="contact-icon">
                                  <span>
                                      <i aria-hidden="true" className="icon icon-map-marker1"></i>
                                  </span>
                              </div>
                              <div className="contact-detail">
                                  <h5>Office Address : </h5>
                                  <span>Level 6, 541 St Kilda Road </span>
                                  <span>Melbourne, VIC 3004</span>
                              </div>
                          </div>
                          <div className="contact-detail-container">
                              <div className="contact-icon">
                                  <span>
                                      <i aria-hidden="true" className="icon icon-phone-call2"></i>
                                  </span>
                              </div>
                              <div className="contact-detail">
                                  <h5>Phone Number : </h5>
                                  <span>Telephone : (+111)-1234-6985,</span>
                                  <span>Mobile : (+01) 235 692 555</span>
                              </div>
                          </div>
                          <div className="contact-detail-container">
                              <div className="contact-icon">
                                  <span>
                                      <i aria-hidden="true" className="icon icon-envelope11"></i>
                                  </span>
                              </div>
                              <div className="contact-detail">
                                  <h5>Mail Address :</h5>
                                  <span><a href="mailto:help@klickpe.in">help@klickpe.in</a></span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-lg-4">
                  <div className="contact-image">
                      <img src={require("../assets/images/agency-img28.png")} alt="" />
                  </div>
              </div>
              <div className="col-lg-4">
                  <ContactForm/>
              </div>
          </div>
      </div>
      <div className="overlay"></div>
  </section>
  </>
  );
}

export {ContactSection};

import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ pageTitle, pageDescription }) => (
  <Helmet>
    <title>{pageTitle}</title>
    <meta name="description" content={(pageDescription == null ? '' : pageDescription)} />
  </Helmet>
);

export default Head;

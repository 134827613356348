import React from 'react';

function Footer(){
    const currentYear = new Date().getFullYear();
    return (
        <>
            <footer id="colophon" className="site-footer footer-primary">
                <div className="footer-overlay"></div>
                <div className="top-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 pe-lg-4">
                                <aside className="widget widget_text img-textwidget">
                                    <div className="footer-logo">
                                        <a href="/"><img src={require("../assets/images/logo1.png")} alt="logo" /></a>
                                    </div>
                                    <div className="textwidget widget-text">
                                        Auctor blandit dolorem primis eius odit soluta molestie? Malesuada elementum aut doloremque labo.
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <aside className="widget">
                                    <h3 className="widget-title">Useful Links</h3>
                                    <ul>
                                        <li>
                                            <a href="#">About us</a>
                                        </li>
                                        <li>
                                            <a href="#">Careers</a>
                                        </li>
                                        <li>
                                            <a href="#">News & Articles</a>
                                        </li>
                                        <li>
                                            <a href="#">Legal Notice</a>
                                        </li>
                                    </ul>
                                </aside>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <aside className="widget">
                                    <h3 className="widget-title">Support</h3>
                                    <ul>
                                        <li>
                                            <a href="#">Help Center</a>
                                        </li>
                                        <li>
                                            <a href="#">Contact Us</a>
                                        </li>
                                        <li>
                                            <a href="#">Payment Center</a>
                                        </li>
                                        <li>
                                            <a href="#">Parent Community</a>
                                        </li>
                                    </ul>
                                </aside>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <aside className="widget widget_text">
                                    <h3 className="widget-title">Contact Information</h3>
                                    <p>Feel free to contact and reach us !</p>
                                    <div className="textwidget widget-text">
                                        <ul>
                                            <li>
                                                <i aria-hidden="true" className="icon icon-map-marker1"></i>
                                                3557 Derek Drive, Florida
                                            </li>
                                            <li>
                                                <a href="tel:+1456657887">
                                                    <i aria-hidden="true" className="icon icon-phone1"></i>
                                                    +1(456)657-887, +01 2599 12
                                                </a>
                                            </li>
                                            <li>
                                                <a href="mailto:help@klickpe.in">
                                                    <i aria-hidden="true" className="icon icon-envelope1"></i>
                                                    <span>help@klickpe.in</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer">
                    <div className="container">
                        <div className="copy-right text-center">Copyright &copy; {currentYear} KlickPe. All rights reserved.</div>
                    </div>
                </div>
            </footer>
        </>
    );
}
export default Footer;
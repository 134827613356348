import React, { useEffect } from 'react';
import $ from 'jquery';
import 'jquery-circle-progress';

const CircleProgressBar = () => {
  useEffect(() => {
    const animateElements = () => {
      $('.circle-progressbar').each(function() {
        var elementPos = $(this).offset().top;
        var topOfWindow = $(window).scrollTop();
        var percent = $(this).find('.circle-bar').attr('data-percent');
        var animate = $(this).data('animate');
        if (elementPos < topOfWindow + $(window).height() - 30 && !animate) {
          $(this).data('animate', true);
          $(this).find('.circle-bar').circleProgress({
            startAngle: -Math.PI / 2,
            value: percent / 100,
            thickness: 3,
            lineCap: 'round',
            size: 125.0,
            emptyFill: 'rgba(255, 255, 255, 0.5)',
            fill: {
              color: '#FFFFFF'
            }
          }).on('circle-animation-progress', function(event, progress, stepValue) {
            $(this).find('.circle-number').text((stepValue * 100).toFixed() + "%");
          }).stop();
        }
      });
    };

    // Initial animation
    animateElements();

    // Scroll event listener
    $(window).scroll(animateElements);

    // Cleanup function
    return () => {
      $(window).off('scroll', animateElements);
    };
  }, []);

  return (
    <>
    <div className="achivement-wrapper">
      <div className="goal-wrapper">
        <div className="circle-progressbar" data-animate="false">
          <div className="circle-bar" data-percent="95">
            <div className="circle-number"></div>
            <div className="goal">
              <h4>Marketing</h4>
              <span>Porro tincidunt duis tincidunt nostrum fais.</span>
            </div>
          </div>
        </div>
      </div>
      <div className="goal-wrapper">
        <div className="circle-progressbar" data-animate="false">
          <div className="circle-bar" data-percent="90">
            <div className="circle-number"></div>
            <div className="goal">
              <h4>Solution</h4>
              <span>Porro tincidunt duis tincidunt nostrum fais.</span>
            </div>
          </div>
        </div>
      </div>
      <div className="goal-wrapper">
        <div className="circle-progressbar" data-animate="false">
          <div className="circle-bar" data-percent="96">
            <div className="circle-number"></div>
            <div className="goal">
              <h4>Success</h4>
              <span>Porro tincidunt duis tincidunt nostrum fais.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CircleProgressBar;
